<template>
  <div class="pa-3 mt-6">
    <div class="d-flex justify-center"><h2 class="mb-6">Cost</h2></div>
    <div v-for="(cost, index) in budgetList" :key="index" class="d-flex flex-row">
      <v-text-field
        v-model="cost.item"
        :readonly="viewOnly"
        class="student-research-link-input"
        label="Item"
        outlined
        rounded
      />
      <v-text-field
        v-model="cost.quantity"
        :readonly="viewOnly"
        type="number"
        class="student-research-link-input ml-2"
        label="Quantity"
        outlined
        rounded
        @input="countSubtotal(index, cost)"
      />
      <v-text-field
        v-model="cost.price"
        :readonly="viewOnly"
        type="number"
        class="student-research-link-input ml-2"
        label="Price"
        outlined
        rounded
        @input="countSubtotal(index, cost)"
      />
      <v-text-field
        v-model="cost.subtotal"
        :readonly="viewOnly"
        class="student-research-link-input ml-2"
        label="Sub-Total"
        outlined
        rounded
      />
      <v-btn
        v-if="budgetList.length > 1"
        :disabled="viewOnly"
        class="mt-2"
        icon
        rounded
        large
        @click="removeItem(index)"
      >
        <v-icon color="red">mdi-trash-can</v-icon>
      </v-btn>
    </div>

    <div class="module-edit__add">
      <v-btn
        x-large
        rounded
        :disabled="viewOnly"
        class="module-edit__add-button white--text font-weight-bold"
        depressed
        outlined
        color="green"
        @click="populate()"
      >
        <v-icon class="module-edit__add-icon font-weight-bold mr-1">mdi-plus</v-icon>Add Item
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref, watch, watchEffect } from '@vue/composition-api';

export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const budgetList = ref([
      {
        item: '',
        quantity: '',
        price: '',
        subtotal: ''
      }
    ]);

    function populate() {
      const obj = {
        item: '',
        quantity: '',
        price: '',
        subtotal: ''
      };
      budgetList.value.push(obj);
    }

    function removeItem(index) {
      budgetList.value.splice(index, 1);
    }

    function countSubtotal(index, cost) {
      if (cost.quantity && cost.price) {
        const subtotal = parseFloat(cost.quantity).toFixed(2) * parseFloat(cost.price).toFixed(2);
        budgetList.value[index].subtotal = parseFloat(subtotal).toFixed(2);
      }
    }

    watch(
      budgetList,
      val => {
        ctx.emit('set-project-budget', budgetList.value);
      },
      { deep: true }
    );

    watchEffect(() => {
      if (props.data.projectCosts) {
        budgetList.value = props.data.projectCosts;
      }
    });

    return { budgetList, populate, removeItem, countSubtotal };
  }
};
</script>
