














































































































































































































































/* eslint-disable no-param-reassign */
import { computed, ref, watchEffect } from '@vue/composition-api';
import axios from 'axios';
import MProjectKeyResult from '@/components/molecules/deliverable/m-project-key-result.vue';
import MBudgetTable from '@/components/molecules/deliverable/m-budget-table.vue';
import MTimeFrame from '@/components/molecules/deliverable/m-time-frame.vue';
import MFileUpload from '@/components/molecules/deliverable/m-file-upload.vue';

export default {
  components: {
    MProjectKeyResult,
    MBudgetTable,
    MTimeFrame,
    MFileUpload
  },

  props: {
    adkList: {
      type: Array,
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, Array],
      default: () => {}
    },
    fetchDeliverables: {
      type: Function,
      default: () => {}
    },
    upload: {
      required: false,
      type: Function,
      default: () => {}
    }
  },

  setup(props: any) {
    const isLoading = ref(false);
    const projectKeys: any = ref();
    const projectRisks: any = ref();
    const projectBudget: any = ref();
    const projectTimeline: any = ref();
    const projectObjective = ref('');
    const projectFile: any = ref();
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');
    const verifyLoading = ref(false);
    const apiErrors = ref<Array<string>>([]);

    const pitchAdk = computed(() => {
      return props.adkList?.filter(adk => adk.name.toLowerCase() === 'pitch')[0];
    });

    const pitchDraft = computed(() => {
      return pitchAdk.value?.valueDrafts?.filter(v => v.finalDraft === true)[0];
    });

    const demoAdk = computed(() => {
      return props.adkList?.filter(adk => adk.name.toLowerCase() === 'demo')[0];
    });

    const submittedVideo = computed({
      get: () => demoAdk.value?.submittedVideo,
      set: newVal => {
        props.adkList.filter(adk => adk.name.toLowerCase() === 'demo')[0].submittedVideo = newVal;
      }
    });

    const demoLink = ref(
      demoAdk.value?.submittedVideo ? `https://youtu.be/${demoAdk.value?.submittedVideo?.id}` : ''
    );

    const ideateAdk = computed(() => {
      return props.adkList?.filter(adk => adk.name.toLowerCase() === 'ideate')[0];
    });

    const ideateDraft = computed(() => {
      return ideateAdk.value?.valueDrafts?.filter(v => v.finalDraft === true)[0];
    });

    const setKeys = val => {
      projectKeys.value = val;
    };

    const setRisks = val => {
      projectRisks.value = val;
    };

    const setBudget = val => {
      projectBudget.value = val;
    };

    const setTimeline = val => {
      projectTimeline.value = val;
    };

    const setFiles = val => {
      projectFile.value = val;
    };

    watchEffect(() => {
      if (props.data.projectObjective) {
        projectObjective.value = props.data.projectObjective;
      }
    });

    async function verifyLink() {}

    async function updateDeliverable() {
      isLoading.value = true;
      try {
        const data = {
          adks: props.adkList,
          projectKeys: projectKeys.value,
          projectRisks: projectRisks.value,
          projectCosts: projectBudget.value,
          projectTimeline: projectTimeline.value,
          projectDocument: projectFile.value,
          projectObjective: projectObjective.value
        };
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isLoading.value = false;
      }
    }

    return {
      pitchAdk,
      pitchDraft,
      demoAdk,
      demoLink,
      ideateAdk,
      ideateDraft,
      projectKeys,
      updateDeliverable,
      setKeys,
      setRisks,
      setBudget,
      projectTimeline,
      setTimeline,
      setFiles,
      isLoading,
      successMsg,
      errorMsg,
      msg,
      verifyLink,
      verifyLoading,
      submittedVideo,
      apiErrors,
      projectObjective
    };
  }
};
