


















































































import { ref, watch, watchEffect, computed } from '@vue/composition-api';

export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any, ctx: any) {
    const timeline: any = ref([
      {
        item: '',
        deadlineDate: ''
      }
    ]);

    const menu = ref(false);

    function populate() {
      const obj = {
        item: '',
        deadlineDate: ''
      };
      timeline.value.push(obj);
    }

    function removeItem(index) {
      timeline.value.splice(index, 1);
    }

    const dealineList = computed(() => {
      return timeline.value
        .slice()
        .sort((a, b) => Date.parse(a.deadlineDate) - Date.parse(b.deadlineDate));
    });

    watch(
      timeline,
      val => {
        ctx.emit('set-project-timeline', timeline.value);
      },
      { deep: true }
    );

    watchEffect(() => {
      if (props.data?.projectTimeline) {
        timeline.value = props.data?.projectTimeline;
      }
    });

    return { timeline, populate, removeItem, menu, dealineList };
  }
};
